
// @ is an alias to /src test env
import { reactive, ref } from "vue";
import * as moment from "moment";
import Swal from "sweetalert2";
import Auth from "../../services/routes";
import Header from "../../components/Header/index.vue";
import lodash from "lodash";
export default {
  name: "Home",
  components: { Header },
  data() {
    return {
      render: false,
      fileImage: null,
      showModal: false,
      fileName: false,
      activetab: 1,
      nome_musculo: null,
      imagem_musculo: null,
      load: false,
      tableData: [],
      API: null,
      expandRowKeys: [],
      pages: 1,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 500, 1000],
        total: 0,
      },
    };
  },
  computed: {
    queriedData() {
      let result = lodash.orderBy(this.tableData, ["ID"], ["desc"]);
      /*
                                      let filtered = lodash.filter(result, item => {
                                        if(this.filter.filterDefault === "ALL") {
                                          return item.DES_FINANCE_CATEGORY.indexOf('') >=0;
                                        }
                                        else {
                                          return item.DES_FINANCE_CATEGORY.indexOf(this.filter.filterDefault) >=0;
                                        }  
                                      });

                                      this.tableDataFiltered = filtered
                                      */
      return result.slice(this.from, this.to);
    },
    pagedData() {
      if (!this.tableDataFiltered)
        return this.tableData.slice(this.from, this.to);
      else return this.tableDataFiltered.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      if (!this.tableDataFiltered)
        this.pagination.total = this.tableData.length;
      else this.pagination.total = this.tableDataFiltered.length;

      if (!this.tableDataFiltered) return this.tableData.length;
      else return this.tableDataFiltered.length;
    },
  },
  methods: {
    handleExpandChange(row, expandedRows) {
      const id = row.idParaOrganizar;
      const lastId = this.expandRowKeys[0];
      // disable mutiple row expanded
      this.expandRowKeys = id === lastId ? [] : [id];
    },
    clicker() {},
    rowClicked(row) {
      this.$refs.tableData.toggleRowExpansion(row);
    },
    showFile() {
      var file = this.$refs.loadImage.files[0];
      const getBase64 = (file) =>
        new Promise(function (resolve, reject) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject("Error: ", error);
        });
      getBase64(file)
        .then((result) => {
          this.render = result;
        })
        .catch((e) => console.log("deu erro:", e));
    },
    SendImage() {
      this.load = true;
      let data = {
        nome: this.nome_musculo,
        imagem: this.render,
      };
      Auth.CadastroEquipamento(data)
        .then((r) => {
          console.log(r.data.resultado);
          if (r.data.resultado === "Equipamento cadastrado anteriormente") {
            this.$notify({
              message: "Equipamento já cadastrado!",
              title: "Falha!",
              type: "Error",
            });
          } else {
            this.$notify({
              message: "Cadastrado com Sucesso!",
              title: "Sucesso",
              type: "success",
            });
          }
        })
        .catch((e) => {
          this.$notify({
            message: "Não foi possivel fazer esta ação",
            title: "Erro",
            type: "danger",
          });
        })
        .finally(() => {
          this.showModal = false;
          this.load = false;
          this.render = false;
          this.nome_musculo = null;
          this.getItens();
          this.pages = 1;
        });
    },
    getItens() {
      this.load = true;
      Auth.getEquipamento()
        .then((r) => {
          this.tableData = r.data.equipamento.reverse();
          let i = 0;
          for (; i <= this.tableData.length - 1; i++) {
            this.tableData[i].idParaOrganizar = i;
          }
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(row) {
      this.load = true;
      let data = {
        id: row.id,
        tabela: 2,
      };
      Auth.deleteItem(data)
        .then((r) => {
          this.$notify({
            message: "Deletado com Sucesso!",
            title: "Sucesso",
            type: "success",
          });
        })
        .finally(() => {
          this.load = false;
          this.getItens();
        });
    },
  },
  mounted() {},
  created() {
    this.getItens();
    Auth.defaultW().then((r) => {
      this.API = r;
    });
  },
  setup() {
    const state = reactive({
      testes: "dCasa",
      numero: ref(0),
    });

    return {
      state,
    };
  },
};
